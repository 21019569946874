import { DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppButton } from "components/button/AppButton";
import { useGetSectors } from "features/jobs/hooks/useGetSectors";
import { useCreateProject } from "features/settings/hooks/useCreateProject";
import { QUERY_KEY_FOR_TALENT_PROFILE } from "features/settings/hooks/useGetTalentProfile";
import { ICreateProject } from "features/settings/types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { IdentifierProps } from "types";
import {
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";

export const AddProject = ({ open, handleClose }: IdentifierProps) => {
  const [currentWork, setCurrentWork] = useState(false);
  const [form] = Form.useForm();
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateProject();
  const { data: sectorData, isLoading: loadingSector } = useGetSectors();


  const handleSubmit = (data: any) => {

    const props: ICreateProject = {
      talent_id: userInfo?.talent?.id,
      title: data.title,
      description: data.description,
      link: data.link,
      token,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Volunteer Experience Created Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_TALENT_PROFILE],
        });
        handleClose();
      },
    });
  };

  return (
    <Modal
      title="Add Project"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="45rem"
      style={{ top: 10 }}
    >
      <div>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          form={form}
          initialValues={{ is_current: false }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
            <Form.Item
              name="title"
              label="Title"
              rules={textInputValidationRules}
            >
              <Input placeholder="Enter Title" />
            </Form.Item>
            <Form.Item
              name="link"
              label="Project Link"
              rules={textInputValidationRules}
            >
              <Input placeholder="Enter project link" />
            </Form.Item>
            <Form.Item
              className="col-span-2"
              name="description"
              label="Description"
            >
              <TextArea />
            </Form.Item>
          </div>
          <div className="flex">
            <AppButton type="submit" isLoading={isLoading} />
          </div>
        </Form>
      </div>
    </Modal>
  );
};
