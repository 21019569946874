import { DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AppButton } from "components/button/AppButton";
import { WORK_MODELS } from "constants/general";
import { useCreateCertification } from "features/settings/hooks/useCreateCertification";
import { QUERY_KEY_FOR_TALENT_PROFILE } from "features/settings/hooks/useGetTalentProfile";
import { ICreateCertification } from "features/settings/types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { IdentifierProps } from "types";
import {
  generalValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";
import DragNDrop from "utils/DragNdrop";

export const AddCertification = ({ open, handleClose }: IdentifierProps) => {
  const [currentWork, setCurrentWork] = useState(false);
  const [form] = Form.useForm();
  const { token, userInfo } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useCreateCertification();
  const [file, setFile] = useState<any>();

  const [title, setTitle] = useState<string>('');
  const [issuer, setIssuer] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [issued_date, setIssued_date] = useState<any>();
  const [expiry_date, setExpiry_date] = useState<any>();
  

  const handleSubmit = () => {

    const startDate = issued_date ? issued_date.format("YYYY-MM-DD") : null;
    const endDate = expiry_date ? expiry_date.format("YYYY-MM-DD") : null;
    console.log(startDate);
    const props: ICreateCertification = {
      talent_id: userInfo?.talent?.id,
      title: title,
      issuer: issuer,
      link: link,
      file: file,
      issued_date: startDate,
      expiry_date: endDate,
      token,
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Created Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_TALENT_PROFILE],
        });
        handleClose();
      },
    });
  };

  const handleIssuedDateChange = (date: any, dateString: any) => {
    setIssued_date(date);
  };

  const handleExpiryDateChange = (date: any, dateString: any) => {
    setExpiry_date(date);
  };
  

  return (
    <Modal
      title="Add Certification"
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      width="45rem"
      style={{ top: 10 }}
    >
      <div>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleSubmit}
          form={form}
          initialValues={{ is_current: false }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
            <Form.Item
              name="title"
              label="Title"
              rules={textInputValidationRules}
            >
              <Input placeholder="Enter Certification Title" onChange={(e)=>setTitle(e.target.value)} />
            </Form.Item>
            <Form.Item
              name="issuer"
              label="Issuer"
              rules={textInputValidationRules}
            >
              <Input placeholder="Enter Certification Issuer" onChange={(e)=>setIssuer(e.target.value)} />
            </Form.Item>

              <Form.Item name="issued_date" label="Date Issued">
                <DatePicker className="w-full" format="YYYY-MM-DD" onChange={handleIssuedDateChange} />
              </Form.Item>

              <Form.Item name="expiry_date" label="Expiry Date">
                <DatePicker className="w-full" format="YYYY-MM-DD" onChange={handleExpiryDateChange} />
              </Form.Item>
              <Form.Item
                name="link"
                label="Link"
                rules={textInputValidationRules}
                className="col-span-2"
                >
              <Input placeholder="Enter Certification verification link"  onChange={(e)=>setLink(e.target.value)}/>
            </Form.Item>            
          </div>
        </Form>
            <div className="col-span-2">
                <p>Upload certification file</p>
                <DragNDrop onFileSelected={setFile} width="100%"/>
            </div>

        <div className="flex pt-8">
            <AppButton type="submit" isLoading={isLoading} handleClick={()=>handleSubmit()} />
        </div>
      </div>
    </Modal>
  );
};
