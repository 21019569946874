import { Modal, Steps, Progress } from "antd";
import { IdentifierProps } from "types";

export const TalentProgressJobs = ({ open, handleClose, data }: IdentifierProps) => {
  
  return (
    <Modal
      title="Incomplete Profile"
      open={open}
      //onCancel={() => handleClose(false)}
      footer={null}
      width="75%"
      centered
    >

    <p>Please complete your profile to access job board.</p>
    <div className="pt-4">
        <div className="py-4">
            <Progress percent={data?.total} />
        </div>
    
        <Steps
            direction="vertical"
            current={ data?.profile_section === 100 ? 1 :data?.profile_section === 100 && data?.documents == 100 ? 2 :  0}
            className="text-primary"
            items={[
            {
                title: 'Complete your profile',
                description:"",
                status: data?.profile_section === 100 ? 'finish': 'wait'
            },
            {
                title: 'Upload your  Documents',
                description:"",
                status: data?.documents === 100 ? 'finish': 'wait'
            },
            {
                title: 'Take Personality Test',
                description:"",
                status: data?.profile_section === 100 ? 'finish': 'wait'
            },
            ]}
        />
        </div>
    </Modal>
    
  );
};
function useGetQueryClient() {
  throw new Error("Function not implemented.");
}
