import { Form, Input, Modal } from "antd";
import { AppButton } from "components/button/AppButton";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IdentifierProps } from "types";
import { textInputValidationRules } from "utils/formHelpers/validations";
import { useCreateFolder } from "../hooks/useCreateFolder";
import { IFolderProps } from "../types";
import { openNotification } from "utils/notification";
import { QUERY_KEY_FOR_FOLDERS } from "../hooks/useGetFolders";
import { useQueryClient } from "react-query";
import { useGetSingleFolder } from "../hooks/useGetSingleFolder";
import { useEffect } from "react";

export const CreateFolder = ({ open, handleClose, id }: IdentifierProps) => {
  const { userInfo, token } = useGetUserInfo();
  const { mutate, isLoading } = useCreateFolder();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data, isSuccess } = useGetSingleFolder({
    id: id as unknown as number,
    token,
  });

  useEffect(() => {
    if (isSuccess && id) {
      form.setFieldsValue({
        name: data.name,
      });
    } else {
      form.resetFields();
    }
  }, [data, id, isSuccess, form]);

  const handleSubmit = (data: any) => {

    const props: IFolderProps = {
      recruiter_id: userInfo?.recruiter?.id,
      token,
      name: data.name,
      userInfo
    };
    mutate(props, {
      onError: (err: any) => {
        let errorTitle = "Error";
        let errorDescription = "An error occurred, please try again.";

        if (err.response && err.response.data &&  err.response.data.error) {
          errorTitle = "Error: " + err.response.data.error;
          errorDescription = err.response.data.description || "Please try again.";
        }

        openNotification({
          title: errorTitle,
          state: "error",
          description: errorDescription,
          duration: 6,
        });
      },
      onSuccess: (res: any) => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Folder Created Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_FOLDERS],
        });
        handleClose();
      },
    });
  };
  return (
    <Modal
      style={{ top: 20 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title={id ? "Edit folder" : "Add folder"}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        className="mt-5"
        form={form}
      >
        <Form.Item
          name="name"
          label="Folder Name"
          rules={textInputValidationRules}
        >
          <Input placeholder="Please enter folder name" />
        </Form.Item>

        <AppButton type="submit" label="Save" isLoading={isLoading} />
      </Form>
    </Modal>
  );
};
