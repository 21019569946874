import axios from "axios";
import { useMutation } from "react-query";
import { ICreateCertification } from "../types";
import { END_POINT } from "config/environment";

export const createCertification = async (props: ICreateCertification) => {
  const url = `${END_POINT.BASE_URL}/v1/save-certification`;
  const config = {
    headers: {
      Accept: "application/json",
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${props.token}`,
    },
  };
  // necessary to make immediate changes when in  a central place when schema changes
  const data: any = {
    talent_id: props.talent_id,
    title: props.title,
    issuer: props.issuer,
    link: props.link,
    file: props.file,
    issued_date: props.issued_date,
    expiry_date: props.expiry_date
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateCertification = () => {
  return useMutation(createCertification);
};
