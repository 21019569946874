import { useEffect, useState } from "react";
import avatar from "assets/images/default.webp";
import { useMutation, useQueryClient } from "react-query";
import { Drawer, Dropdown, Menu, Empty } from "antd";
import { SideBar } from "../sidebar/Sidebar";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
//import { messaging } from "../../../firebase";
import { openNotification } from "utils/notification";
//import { onMessage } from "firebase/messaging";
import { useGetTeams } from "hooks/useGetTeams";
import { useSignIn } from "react-auth-kit";
import { useSwitchTeams } from "hooks/useSwitchTeams";
import { ISwitchTeamsProps } from "types";
import { useGetNotifications, QUERY_KEY_FOR_NOTIFICATION } from "../hooks/useGetNotifications";
//import { OpenNotification } from "./OpenNotification";
import { useMarkNotificationRead } from "../hooks/useMarkNotificationRead";
import { useGetFeedback } from "../hooks/useGetFeedback";
import { ReviewForm } from "features/invites/components/ReviewForm";

interface navProps {
  title: string;
  description: string;
}

export const Navbar = ({ description, title }: navProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { userInfo, token } = useGetUserInfo();
  const { data: teams, isLoading: loading } = useGetTeams();
  const { data: notifications, } = useGetNotifications();
  const [openNot, setOpenNot] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [notData, setNotData] = useState(null);
  const signIn = useSignIn();
  const { mutate, isLoading } = useSwitchTeams();
  const { mutate:markAsRead } = useMarkNotificationRead();
  const { data: feedback, } = useGetFeedback();

  console.log(feedback);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (feedback && feedback?.listing) {
        setReviewOpen(true);
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [feedback]);
/*
  useEffect(()=>{
    onMessage(messaging, (payload:any) => {
      openNotification({
        title: payload.notification.title,
        state: "info",
        description: payload.notification.body,
        duration: 6.0,
      });
    });
  },[])
*/
  const handleSubmit = (data: any) => {
    const props: ISwitchTeamsProps = {
      team_id: data.id,
      token
    };

    mutate(props, {
      onError: (err: any) => {
        openNotification({
          title: "Error",
          state: "error",
          description: err.response.data.msg,
          duration: 8.0,
        });
      },
      onSuccess: (res: any) => {
        const result = res.data;
        if (
          signIn({
            token: result.token,
            tokenType: "Bearer",
            authState: result,
            expiresIn: 500,
          })
        ) {
          openNotification({
            title: "Success",
            state: "success",
            description: "Switched successfully",
            duration: 4.5,
          });
        }
        setTimeout(()=>{
          window.location.reload();
      }, 500);
      },
    });
    //window.location.reload();
  };

  const popNotification = (notification:any)=>{
    setNotData(notification.data);
    const props: any = {
      id: notification.id,
      token
  };
    markAsRead(props, {
      onError: (err: any) => {},
      onSuccess: (res: any) => {
        queryClient.invalidateQueries([QUERY_KEY_FOR_NOTIFICATION]);
      },
    });
  }

  const readNotificationsCount = notifications?.filter((notification: any) => {return notification.read_at === null }).length;
  return (
    <>
    {/*<OpenNotification open={openNot} data={notData} handleClose={() => setOpenNot(false)} />*/}
    <ReviewForm open={reviewOpen} handleClose={()=>{setReviewOpen(false)}} data={feedback} />
      <div className="w-full py-3 mb-3 flex justify-between items-center">
        <i className="ri-menu-line lg:hidden text-xl cursor-pointer" onClick={() => setOpen(true)}></i>
        <div className="lg:flex hidden">
          <div>
            <h1 className="font-bold text-xl">{title}</h1>
            <h3 className="text-sm">{description}</h3>
          </div>
        </div>

        <div className="flex items-center gap-x-4">
        { teams && teams?.length > 0 &&
          <Dropdown
            trigger={["click"]}
            overlay={
              <div className="flex flex-col py-2 px-2 bg-white shadow-xl justify-start items-start min-w-[200px] rounded-lg">
                {
                userInfo.current_team && 
                  <div className="cursor-pointer px-2 py-1 hover:bg-gray-200 w-full rounded text-red-400" onClick={()=>handleSubmit({id:'false'})}>
                    Sign out of {userInfo.current_team.name}
                  </div>
                }
                { 
                  teams?.map((team, i)=>(
                    <div className="py-1 px-2 cursor-pointer hover:bg-gray-200 w-full rounded" key={i} onClick={()=>handleSubmit(team)}>
                      {team.name}
                    </div>
                  ))
                }
              </div>
            }
          >
            <p className="flex justify-center items-center"><i className="ri-arrow-down-s-line text-2xl cursor-pointer"></i><span className="text-sm">{userInfo?.current_team ? userInfo.current_team.name : 'Switch profile'}</span></p>
          </Dropdown>
        }
          <i className="ri-search-line text-xl cursor-pointer"></i>

          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu>
                <div className="p-2">
              { notifications && notifications?.length > 0 ? 
                notifications.map((notification:any, i:number)=>(
                  <Menu.Item key={i} onClick={()=>{ 
                    popNotification(notification);
                    setOpenNot(true);
                  }}>
                    <i className={`${notification.read_at === null ? 'ri-notification-3-fill text-red-500' : 'ri-notification-3-line'} pr-2`}></i>{notification.data.subject}
                  </Menu.Item>
                ))
                :
                <Empty/>
              }
              </div>
              </Menu>
            }
          >
            <i className={`text-xl cursor-pointer ${readNotificationsCount > 0 ? 'ri-notification-3-fill text-red-500':'ri-notification-3-line'}`}><span className="absolute top-3 text-sm">{readNotificationsCount > 0 ? readNotificationsCount: ''}</span></i>
          </Dropdown>

          <img src={userInfo?.photo != null ? END_POINT.BASE+'/storage/'+userInfo?.photo : avatar} alt="avatar" className="h-12 w-12 rounded-full mb-2"/>
        </div>
      </div>

      {/* Drawer for mobile navbar */}
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        style={{ background: "var(--app-color-primary)" }}
      >
        <SideBar />
      </Drawer>
    </>
  );
};

