import axios from "axios";
import { useMutation } from "react-query";
import { END_POINT } from "config/environment";
import { membersProps } from "../types";

export const createMember = async (props: membersProps) => {
  const url = `${END_POINT.BASE_URL}/v1/invite-team-member`;
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${props.token}`,
    },
  };

  const data: any = {
    password: props.password,
    name: props.name,
    email: props.email,
  };

  const response = await axios.post(url, data, config);
  return response;
};

export const useCreateMember = () => {
  return useMutation(createMember);
};