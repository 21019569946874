import React, { useState } from "react";
import { AddEducationHistory } from "./AddEducationHistory";
import { TEducationHistory } from "features/settings/types";
import { QUERY_KEY_FOR_TALENT_PROFILE } from "features/settings/hooks/useGetTalentProfile";
import { useDelete } from "hooks/useDelete";
import { Dropdown, Popconfirm, Skeleton } from "antd";

const deleteEndPointUrl = "delete-education-history/";
export const EducationHistory: React.FC<{
  data: TEducationHistory[];
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  const [addHistory, setAddHistory] = useState(false);
  const [educationId, setEducationId] = useState<number>();
  const { removeData } = useDelete({
    queryKey: QUERY_KEY_FOR_TALENT_PROFILE,
    deleteEndPointUrl,
  });

  const handleAddEducation = () => {
    setEducationId(undefined);
    setAddHistory(true);
  };

  const handleEducation = (id: number) => {
    setEducationId(id);
    setAddHistory(true);
  };

  return (
    <>
      <AddEducationHistory
        id={educationId}
        open={addHistory}
        handleClose={() => setAddHistory(false)}
      />
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
        <div className="lg:col-span-2">
          <h4 className="font-semibold text-lg text-primary">
            Education History
          </h4>
          <h3>Add your education background</h3>
        </div>
        <div className="md:col-span-2 lg:col-span-4">
          <Skeleton active loading={isLoading}>
            {data.map((item) => (
              <div className="border bg-white rounded-md px-3 py-4 mb-4 hover:border-secondary">
                <div className="flex justify-between items-start">
                  <div>
                    <h5 className="text-xs font-semibold text-primary">
                      {item.school}
                    </h5>
                    <h4 className="text-base font-medium text-primary pb-3 pt-1">
                      {item.course}
                    </h4>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <div>
                          <div className="Container">
                            <div className="bg-gray-100 rounded shadow-lg max-w-lg mx-auto border px-3 pt-2 pb-4 text-slate-600">
                              <h3>
                                <span className="font-medium pr-2">Grade:</span>
                                <span> {item.grade}</span>
                              </h3>

                              <h3>
                                <span className="font-medium pr-2">
                                  Degree:
                                </span>
                                <span> {item.degree_id}</span>
                              </h3>

                              {item.description && (
                                <p className="pt-1 text-justify">
                                  <span className="font-medium">
                                    Description:
                                  </span>
                                  <span className="text-sm pl-2">
                                    {item.description}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <i className="ri-eye-line cursor-pointer text-lg hover:text-primary"></i>
                    </Dropdown>
                    <i
                      onClick={() => handleEducation(item.id)}
                      className="ri-pencil-fill cursor-pointer text-lg hover:text-primary"
                    ></i>
                    <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                      title="Delete education history"
                      description="Are you sure to delete this education history?"
                      onConfirm={() => removeData(item.id)}
                    >
                      <i className="ri-delete-bin-line text-red-600 cursor-pointer text-base hover:text-red-800"></i>
                    </Popconfirm>
                  </div>
                </div>
                <div
                  className="flex gap-x-5 text-sm"
                  style={{ color: "#667085" }}
                >
                  <div className="flex items-center gap-x-1">
                    <i className="ri-map-pin-line"></i>
                    <span>Distinction</span>
                  </div>
                  <div className="flex items-center gap-x-1">
                    <i className="ri-time-line"></i>
                    <div>
                      <span className="pr-2">{item.start_date} To</span>
                      <span>
                        {item.is_current === 0
                          ? item.end_date
                          : "Currently working here"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Skeleton>
          <button
            onClick={handleAddEducation}
            className="text-primary font-bold text-base flex items-center gap-1 hover:text-accent"
          >
            <i className="ri-add-line"></i> <span>Add New</span>
          </button>
        </div>
      </div>
    </>
  );
};
