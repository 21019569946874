import { Form, Input, Modal, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import { IdentifierProps } from "types";
import {
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { openNotification } from "utils/notification";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useAddRole } from "../hooks/useAddRole";
import { IUsers } from "../types";
import { useQueryClient } from "react-query";
import { QUERY_KEY_FOR_ROLES } from "../hooks/useGetRoles";

export const AddRole = ({ open, handleClose }: IdentifierProps) => {
  const [form] = Form.useForm();
  const { token } = useGetUserInfo();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAddRole();
  
  const handleFormSubmit = (values: any) => {
    const props: IUsers = {
      token,
      name: values.name,
    };
    mutate(props, {
      onError: () => {
        openNotification({
          title: "Error",
          state: "error",
          description: "Error occurred try again",
          duration: 6.0,
        });
      },
      onSuccess: () => {
        openNotification({
          title: "Success",
          state: "success",
          description: "Added Successfully",
          duration: 4.5,
        });
        form.resetFields();
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_FOR_ROLES],
        });
        handleClose();
      },
    });
  };

  return (
    <Modal
      style={{ top: 20 }}
      footer={false}
      open={open}
      onCancel={() => handleClose()}
      title="Add new Role"
    >
      <Form layout="vertical" requiredMark="optional" className="mt-5"
        onFinish={handleFormSubmit}
        form={form}
      >
    
        <Form.Item
            name="name"
            label="Role Name"
            rules={textInputValidationRules}
        >
            <Input placeholder="Please enter role name" />
        </Form.Item>

        <AppButton label="Submit" type="submit" isLoading={isLoading}  />
      </Form>
    </Modal>
  );
};