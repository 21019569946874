import { routePath } from "config/routeMgt/routePath";
import { NavLink, useNavigate } from "react-router-dom";
import homeIcon from "assets/icons/dashboard.png";
import priceIcon from "assets/icons/pricing.png";
import jobsIcon from "assets/icons/jobs.png";
import talentsIcon from "assets/icons/talents.png";
import settingsIcon from "assets/icons/settings.png";
import logoutIcon from "assets/icons/logout.png";
import { useSignOut } from "react-auth-kit";
import { useGetRecruiterCount } from "features/home/hooks/useGetRecruiterCount";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { Progress } from "antd";
import { AppButton } from "components/button/AppButton";
import avatar from "assets/images/default.webp";
import { END_POINT } from "config/environment";

export const CorporateRecruiter = () => {
  const signOut = useSignOut();

  const { data } = useGetRecruiterCount();
  const { userInfo } = useGetUserInfo();
  const navigate = useNavigate();


  return (
    <div className="flex flex-col justify-between">

      <div className="mt-5 flex flex-col gap-y-3 justify-center px-5">
        <NavLink to={routePath.home} className="sidebarLink">
          <img src={homeIcon} alt="Home Icon" className="h-6" />
          <span className="text-sm">Dashboard</span>
        </NavLink>
        <NavLink to={routePath.team} className="sidebarLink">
          <i className="ri-shield-user-line text-xl"></i>
          <span className="text-sm ">Team</span>
        </NavLink>
        <NavLink to={routePath.jobs} className="sidebarLink">
          <img src={jobsIcon} alt="Job Icon" className="h-6" />
          <span className="text-sm ">Jobs</span>
        </NavLink>

        <NavLink to={routePath.talents} className="sidebarLink">
          <img src={talentsIcon} alt="folder Icon" className="h-6" />
          <span className="text-sm">Talents</span>
        </NavLink>
        <NavLink to={routePath.folders} className="sidebarLink">
          <i className="ri-folder-2-line text-xl"></i>
          <span className="text-sm">Folders</span>
        </NavLink>
        <NavLink to={routePath.pricing} className="sidebarLink">
          <img src={priceIcon} alt="pricing Icon" className="h-6" />
          <span className="text-sm">Pricing</span>
        </NavLink>
        <NavLink to={routePath.settings} className="sidebarLink">
          <img src={settingsIcon} alt="settings Icon" className="h-6" />
          <span className="text-sm">Settings</span>
        </NavLink>
        <NavLink to={routePath.meetingSettings} className="sidebarLink">
          <img src={settingsIcon} alt="settings Icon" className="h-6" />
          <span className="text-sm">Meeting Settings</span>
        </NavLink>

        <NavLink to={routePath.messages} className="sidebarLink">
          <img src={jobsIcon} alt="message Icon" className="h-6" />
          <span className="text-sm">Messages</span>
        </NavLink>

        <div className="sidebarLink cursor-pointer" onClick={() => signOut()}>
          <img src={logoutIcon} alt="logout Icon" className="h-6" />
          <span className="text-sm">Logout</span>
        </div>
      </div>

    { data?.profile.total != 100 &&
      <div className="mx-2">
        <div className="bg-white mx-auto px-2 py-4 rounded-lg">
        <img src={userInfo?.photo != null ? END_POINT.BASE+'/storage/'+userInfo?.photo : avatar} alt="avatar" className="h-12 w-12 rounded-full mb-2"/>
          <div className="flex flex-row items-center">
            <h1 className="text-xs font-bold mb-4">Complete your Profile!</h1>
            <Progress type="circle"strokeColor="#65DE02" strokeWidth={10} percent={data?.profile.total} size={55}/>
          </div>
          
          <p className="text-gray-700 mb-8 text-xs">
            Complete your registration to verify your account.
          </p>
          <AppButton type="button" label="Complete Now" handleClick={()=>navigate('/settings')} />
        </div>
      </div>
    }
  </div>
  );
};
