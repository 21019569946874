import React, { useState } from "react";
import { IWorkHistory } from "features/settings/types";
import { Dropdown, Popconfirm, Skeleton } from "antd";
import { useDelete } from "hooks/useDelete";
import { QUERY_KEY_FOR_TALENT_PROFILE } from "features/settings/hooks/useGetTalentProfile";
import { AddVolunteerExperience } from "./AddVolunteerExperience";

const deleteEndPointUrl = "delete-volunteer-experience/";
export const VolunteerExperience: React.FC<{
  data: IWorkHistory[];
  isLoading?: boolean;
}> = ({ data, isLoading }) => {
  const [addHistory, setAddHistory] = useState(false);
  const { removeData } = useDelete({
    queryKey: QUERY_KEY_FOR_TALENT_PROFILE,
    deleteEndPointUrl,
  });
  return (
    <>
      <AddVolunteerExperience
        open={addHistory}
        handleClose={() => setAddHistory(false)}
      />
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-8 gap-x-4 gap-y-3">
        <div className="lg:col-span-2">
          <h4 className="font-semibold text-lg text-primary">Volunteer Experience</h4>
          <h3>Add your volunteer experiences</h3>
        </div>

        <div className="md:col-span-2 lg:col-span-4">
          <Skeleton active loading={isLoading}>
            {data.map((item) => (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <div className="Container">
                    <div className="bg-gray-100 rounded shadow-lg max-w-lg mx-auto border px-3 pt-2 pb-4 text-slate-600">
                      <h1>
                        <span className="font-medium pr-2">Sector:</span>
                        <span> {item.sector_id}</span>
                      </h1>
                      <p className="pt-1 text-justify">
                        <span className="font-medium">Description:</span>
                        <span className="text-sm pl-2">{item.description}</span>
                      </p>
                    </div>
                  </div>
                }
              >
                <div className="border bg-white rounded-md px-3 py-4 mb-4 cursor-pointer hover:border-secondary">
                  <div className="flex justify-between items-start">
                    <div className="capitalize">
                      <h5 className="text-xs font-semibold text-primary">
                        {item.company}
                      </h5>
                      <h4 className="text-base font-medium text-primary pb-3 pt-1">
                        {item.title}
                      </h4>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <Popconfirm
                  okButtonProps={{
                    style: { backgroundColor: '#f5222d', color: '#ffffff' }, // Custom styles
                  }} 
                        title="Delete work history"
                        description="Are you sure to delete this volunteer experience?"
                        onConfirm={() => removeData(item.id)}
                      >
                        <i className="ri-delete-bin-line text-red-600 cursor-pointer text-base hover:text-red-800"></i>
                      </Popconfirm>
                    </div>
                  </div>
                  <div
                    className="flex gap-x-5 text-sm"
                    style={{ color: "#667085" }}
                  >
                    <div className="flex items-center gap-x-1">
                      <i className="ri-map-pin-line"></i>
                      <span className="capitalize">{item.type}</span>
                    </div>
                    <div className="flex items-center gap-x-1">
                      <i className="ri-time-line"></i>
                      <div>
                        <span className="pr-2">{item.start_date} To</span>
                        <span>
                          {item.is_current === 0
                            ? item.end_date
                            : "Currently working here"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown>
            ))}
          </Skeleton>
          <button
            onClick={() => setAddHistory(true)}
            className="text-primary font-bold text-base flex items-center gap-1 hover:text-accent"
          >
            <i className="ri-add-line"></i> <span>Add New</span>
          </button>
        </div>
      </div>
    </>
  );
};
