import { Form, Input, Select } from "antd";
import { AppButton } from "components/button/AppButton";
import avatar from "assets/images/default.webp";
import bg from "../../talents/assets/images/Image wrap outer.svg";
import { generalValidationRules, textInputValidationRules, phoneNumberValidationRule } from "utils/formHelpers/validations";
import { QUERY_KEY_FOR_RECRUITER_PROFILE, useGetRecruiterProfile } from "../hooks/useGetRecruiterProfile";
import { ChangeEvent, useEffect, useState } from "react";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useUpdateRecruiterProfile } from "../hooks/useUpdateRecruiterProfile";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import { IRecruiterProfile } from "../types";
import { useSignIn } from "react-auth-kit";
import { FileWithPreview } from "features/settings/types";
import { resizeImageToSquare, resizeCoverPhoto } from "utils/resizephoto";
import { COUNTRIES, STATES } from "constants/general";

export const Profile = () => {
  const { data, isLoading } = useGetRecruiterProfile();
  const { userInfo, token } = useGetUserInfo();
  const [form] = Form.useForm();
  const { mutate, isLoading: loadUpdate } = useUpdateRecruiterProfile();
  const queryClient = useQueryClient()
  const [file, setFile] = useState<FileWithPreview | null>(null);
  const [resizedBlob, setResizedBlob] = useState<Blob | null>(null);
  const [coverPhotoBlob, setCoverPhotoBlob] = useState<Blob | null>(null);
  
  const [selectedState, setSelectedState] = useState(null);
  const [filteredCities, setFilteredCities] = useState<any[]>([]);
  const signIn = useSignIn();
  
  useEffect(() => {
    form.setFieldsValue({
      first_name: data?.data.first_name,
      last_name: data?.data.last_name,
      rc_number: data?.data.rc_number,
      company_name: data?.data.company_name,
      phone: data?.data.phone,
      country: data?.data.country,
      state: data?.data.state,
      city: data?.data.city,
      language: data?.data.language,
      prefered_industries: data?.data.prefered_industries,
      prefered_locations: data?.data.prefered_locations,
      linkedin: data?.data.linkedin,
      github: data?.data.github,
      behance: data?.data.behance,
      facebook: data?.data.facebook,
      x: data?.data.x,
      instagram: data?.data.instagram
    });
  }, [form, data]);

  const handleSubmit = (data: any) => {

    const props: IRecruiterProfile = {
      first_name: data.first_name,
      last_name: data.last_name,
      company_name: data.company_name,
      rc_number: data.rc_number,
      photo: resizedBlob ? resizedBlob : null,
      cover_photo: coverPhotoBlob ? coverPhotoBlob : null,
      phone: data.phone ? data.phone :'',
      country: data.country ? data.country :'',
      state: data.state ? data.state : '',
      city: data.city ? data.city : '',
      language: data.language ? data.language : '',
      prefered_industries: data.prefered_industries,
      prefered_locations:  data.prefered_locations,
      linkedin: data.linkedin ? data.linkedin: '',
      github: data.github ? data.github : '',
      behance: data.behance ? data.behance : '',
      facebook: data.facebook ? data.facebook :'',
      x:  data.x ? data.x : '',
      instagram: data.instagram ? data.instagram : '',
      token,
    };

    mutate(props, {
        onError: (err: any) => {
          openNotification({
            title: "Error",
            state: "error",
            description: "Error occurred try again",
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          if(
            signIn({
              token: token,
              tokenType: "Bearer",
              authState: {"token": token,"user":res.data},
              expiresIn: 180,
            }))
            {
          openNotification({
            title: "Success",
            state: "success",
            description: "Profile Updated Successful",
            duration: 4.5,
          });
          form.resetFields();
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY_FOR_RECRUITER_PROFILE],
          });
        }
        },
      }
    );
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile({
        ...selectedFile,
        preview: URL.createObjectURL(selectedFile)
      } as FileWithPreview);
      try {
        const resizedImageBlob = await resizeImageToSquare(selectedFile, 200); // Resize image to a 200x200 square
        setResizedBlob(resizedImageBlob);
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    }
  };


  const coverPhotoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile({
        ...selectedFile,
        preview: URL.createObjectURL(selectedFile)
      } as FileWithPreview);
      try {
        const resizedImageBlob = await resizeCoverPhoto(selectedFile);
        setCoverPhotoBlob(resizedImageBlob);
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    }
  };
  
  const handleStateChange = (value:any) => {
    setSelectedState(value);
    const state = STATES.find((state:any) => state.name === value);
    setFilteredCities(state ? state.cities : []);
  };
  
  return (
    <>
      <Form form={form} disabled={isLoading} onFinish={handleSubmit}>
        <div className="flex justify-between mb-4">
          <div>
            <h1 className="font-semibold text-lg text-accent">Personal info</h1>
            <h3 className="text-sm">Update your details here.</h3>
          </div>
          <div className="flex items-center gap-x-3">
            <AppButton type="button" label="Cancel" variant="transparent" />
            <AppButton type="submit" label="Save" isLoading={loadUpdate} />
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 my-3 md:my-5 gap-3">
          <Form.Item noStyle name="image">
            <Input type="hidden" />
          </Form.Item>
          <div>
            <h3 className="font-medium">Your profile picture</h3>
            <h3 className="text-xs">This will be displayed on your profile</h3>
          </div>
          <div className="relative">
            <div className="flex items-baseline">
              <img src={resizedBlob ? URL.createObjectURL(resizedBlob) : data?.data.dp != null ? data?.data.dp : avatar} alt="avatar" className="h-10 w-10 rounded-full" />
              <input type="file" accept="image/*" style={{ display: 'none' }} id="cover_photo" onChange={handleFileChange}/>
              <i className="ri-edit-2-fill cursor-pointer text-xl text-primary hover:text-primary" onClick={() => document.getElementById('cover_photo')?.click()}></i>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div className="grid grid-cols-1 md:grid-cols-5 my-3 md:my-5 gap-3">
          <Form.Item noStyle name="image">
            <Input type="hidden" />
          </Form.Item>
          <div>
            <h3 className="font-medium">Your cover photo</h3>
            <h3 className="text-xs">This will be displayed on your profile</h3>
          </div>
          <div className="relative">
            <div className="flex items-baseline">
              <img src={coverPhotoBlob ? URL.createObjectURL(coverPhotoBlob) : data?.data.user.cover_photo != null ? data?.data.user.cover_photo : bg} alt="avatar" className="h-8 w-54 rounded-" />
              <input type="file" accept="image/*" style={{ display: 'none' }} id="fileInput" onChange={coverPhotoChange}/>
              <i className="ri-edit-2-fill cursor-pointer text-xl text-primary hover:text-primary" onClick={() => document.getElementById('fileInput')?.click()}></i>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Full Name</h3>
          <div className="col-span-3">
            <div className="flex gap-x-3">
              <Form.Item name="first_name" rules={textInputValidationRules}>
                <Input placeholder="First name" />
              </Form.Item>
              <Form.Item name="last_name" rules={textInputValidationRules}>
                <Input placeholder="Last name" />
              </Form.Item>
            </div>
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Email Address</h3>

          <Form.Item
            // name="email"
            className="col-span-2"
          >
            <Input placeholder={userInfo?.email} disabled />
          </Form.Item>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Phone Number (Optional)</h3>

          <Form.Item
            name="phone"
            className="col-span-2"
            rules={phoneNumberValidationRule}
          >
            <Input placeholder="+2348012345678"  />
          </Form.Item>
        </div>

        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Company Details</h3>
          <div className="col-span-3">
            <div className="flex gap-x-3">
              <Form.Item name="company_name" rules={textInputValidationRules}>
                <Input placeholder="Company name" />
              </Form.Item>
              <Form.Item name="rc_number" rules={textInputValidationRules}>
                <Input placeholder="Rc number" />
              </Form.Item>
            </div>
          </div>
        </div>

        <br />
        <hr />

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Country</h3>

          <Form.Item
            name="country"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select options={COUNTRIES} allowClear placeholder="Select" />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>State</h3>

          <Form.Item
            name="state"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select allowClear placeholder="Select" 
              options={STATES.map(state => ({ value: state.name, label: state.name }))}
              onChange={handleStateChange}
            />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>City</h3>

          <Form.Item
            name="city"
            className="col-span-2"
            rules={generalValidationRules}
          >
            <Select
              options={filteredCities.map(city => ({ value: city, label: city }))}
              allowClear
              placeholder="Select"
            />
          </Form.Item>
        </div>

        <br />
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Linkedin profile (Optional)</h3>

          <Form.Item
            name="linkedin"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://linkedin.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Github profile (Optional)</h3>

          <Form.Item
            name="github"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://github.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Behance profile (Optional)</h3>

          <Form.Item
            name="behance"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://behance.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Facebook profile (Optional)</h3>

          <Form.Item
            name="facebook"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://facebook.com"  />
          </Form.Item>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>X profile (Optional)</h3>

        <Form.Item
            name="x"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://twitter.com"  />
          </Form.Item>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-5 mt-3 md:mt-5 gap-2">
          <h3>Instagram profile (Optional)</h3>

        <Form.Item
            name="instagram"
            className="col-span-2"
            //rules={generalValidationRules}
          >
            <Input placeholder="https://instagram.com"  />
          </Form.Item>
        </div>
        
        <br />
        <hr />
      </Form>
    </>
  );
};