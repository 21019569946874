import { Drawer, Form, Input } from "antd";
import { AppButton } from "components/button/AppButton";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { IdentifierProps } from "types";
import { openNotification } from "utils/notification";
import { useQueryClient } from "react-query";
import {
  emailValidationRules,
  textInputValidationRules,
} from "utils/formHelpers/validations";
import { useCreateMember } from "../hooks/useCreateMember";
import { QUERY_KEY_FOR_TEAM_MEMBERS } from "../hooks/useGetMembers";

export const AddMember = ({ open, handleClose }: IdentifierProps) => {
  const { token } = useGetUserInfo();
  const { mutate, isLoading } = useCreateMember();
  const queryClient = useQueryClient();

  const handleSubmit = (values: any) => {
    mutate(
      {
        ...values,
        token,
      },
      {
        onError: (err: any) => {
          let errorTitle = "Error";
          let errorDescription = "An error occurred, please try again.";
  
          // Check if the error response contains a message
          if (err.response && err.response.data &&  err.response.data.error) {
            errorTitle = "Error: " + err.response.data.error;
            errorDescription = err.response.data.description || "Please try again.";
          }
  
          console.log(err);
          openNotification({
            title: errorTitle,
            state: "error",
            description: errorDescription,
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          openNotification({
            title: "Success",
            state: "success",
            description: "",
            duration: 4.5,
          });
          queryClient.invalidateQueries([QUERY_KEY_FOR_TEAM_MEMBERS]);
          handleClose();
        },
      }
    );
  };

  return (
    <Drawer title="Add a new team member" open={open} onClose={() => handleClose()}>
      <p>If the recruiter already exist, they will be added to your team else a recruiter account will be created and added to your team</p>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
        <Form.Item
          name="name"
          label="Full name"
          rules={textInputValidationRules}
        >
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={emailValidationRules}>
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={textInputValidationRules}
        >
          <Input.Password />
        </Form.Item>
        <AppButton type="submit" label="Add" isLoading={isLoading} />
      </Form>
    </Drawer>
  );
};
