import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import { useParams } from "react-router-dom";
import { useGetSingleJob } from "../hooks/useGetSingleJob";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useGetSectors } from "../hooks/useGetSectors";
import { Skeleton } from "antd";

export const JobDetails = () => {
  const { token } = useGetUserInfo();
  const params = useParams();
  const id = params.id;
  const { data: sectorData } = useGetSectors();
  const { data, isLoading } = useGetSingleJob({
    id: id as unknown as number,
    token,
  });

  const sector = sectorData?.find((item) => {
    return item.id === data?.sector_id;
  });

  return (
    <DashboardLayout>
      <Navbar
        title={isLoading ? "Loading.." : `${data?.title} details`}
        description={isLoading ? "Loading.." : `${data?.title} description`}
      />
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <Skeleton active loading={isLoading}>
        <div>
          <h2 className="text-primary font-semibold text-xl">{data?.title}</h2>
          <div className="text-accent text-sm mt-3 flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <i className="ri-briefcase-line text-lg"></i>
              <span>{data?.type}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="ri-briefcase-line text-lg"></i>
              <span>{data?.level}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="ri-map-pin-line text-lg"></i>{" "}
              <span>{data?.location_city}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="ri-home-5-line text-lg"></i>
              <span>{data?.company_name}</span>
            </div>

            <div className="flex items-center gap-2">
              <i className="ri-calendar-line text-lg"></i>
              <span>Expiring date:</span>
              <span>{data?.expiry_date}</span>
            </div>

            <div className="flex items-center gap-2">
              <i className="ri-briefcase-line text-lg"></i>
              <span>Years of experience:</span>
              <span>{data?.years_of_experience}</span>
            </div>
            <div className="flex items-center gap-2">
              <i className="ri-list-settings-fill text-lg"></i>
              <span>Sector:</span>
              <span>{sector?.name}</span>
            </div>
          </div>

          <div className="my-4">
            <h3 className="text-base font-semibold pb-1">Job Description:</h3>
            <p className="text-sm">{data?.description}</p>
          </div>

          <div>
            <h3 className="text-base font-semibold pb-2">Job Skills:</h3>
            <div className="flex items-center flex-wrap gap-3">
              {data?.skills.map((item: any) => (
                <button
                  key={item.id}
                  className="bg-white shadow-sm border px-2 py-1 rounded text-sm"
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
        </Skeleton>
        <div></div>
      </div>
    </DashboardLayout>
  );
};
