import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Navbar } from "components/layout/components/Navbar";
import { JobsTable } from "../components/JobsTable";
import { NewJobForm } from "../components/NewJobForm";
import { useEffect, useState } from "react";
import { FilterJob } from "../components/FilterJob";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { useGetTalentCount } from "features/home/hooks/useGetTalentCount";
import { TalentProgressJobs } from "features/home/components/TalentProgressJobs";

export const Jobs = () => {
  const { token, userInfo } = useGetUserInfo();
  const [openNewJob, setOpenNewJob] = useState(false);
  const [jobId, setJobId] = useState<number>();
  const [jobFilter, setJobFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen ] = useState(false);
  const { data } = useGetTalentCount();

  const addJob = () => {
    setJobId(undefined);
    setOpenNewJob(true);
  };

  const handleJob = (id: number) => {
    setJobId(id);
    setOpenNewJob(true);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    if (userInfo?.talent  && data?.profile.total !== 100) {
      setIsOpen(true);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <TalentProgressJobs
        open={isOpen}
        data={data?.profile}
        handleClose={() => setIsOpen(false)}
      />
      <NewJobForm
        id={jobId}
        open={openNewJob}
        handleClose={() => setOpenNewJob(false)}
      />
      <FilterJob open={jobFilter} handleClose={() => setJobFilter(false)} />
      <Navbar title="Jobs" description={ userInfo?.recruiter ? "View and Manage Jobs" : "Job Board" } />

      { userInfo?.recruiter && <div className="flex justify-end">
        <button className="button flex items-center gap-x-2" onClick={addJob}>
            <i className="ri-checkbox-blank-circle-fill text-xs"></i>
            <span>Create Job</span>
          </button>
        </div>
      }

      <div className="bg-gray-100 px-4 py-3 mt-8 rounded-md">
        <div className="flex gap-3 flex-col md:flex-row md:justify-between md:items-center">
          <div className="flex items-center">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ">
                <i className="ri-search-line text-xl text-gray-500"></i>
              </div>
              <input
                type="search"
                className="border focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5 pr-24"
                placeholder="Search Jobs"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              className="transparentButton flex items-center gap-x-2"
              style={{ background: "white" }}
              onClick={() => setJobFilter(true)}
            >
              <i className="ri-filter-3-line text-base"></i>
              <span>Filters</span>
            </button>
          </div>
        </div>
      </div>

      <JobsTable handleJob={handleJob} searchTerm={searchTerm} />
    </DashboardLayout>
  );
};
