import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { routePath } from "./routePath";
import { adminRoutePath } from "./adminRoutePath";
import { GlobalContextProvider } from "stateManagers/GlobalContext";
import { SignIn } from "features/Authentication/pages/SignIn";
import { SignUp } from "features/Authentication/pages/SignUp";
import { GoogleCallback } from "features/Authentication/pages/GoogleCallback";
import { LinkedinCallback } from "features/Authentication/pages/LinkedinCallback";
import { ForgotPassword } from "features/Authentication/pages/ForgotPassword";
import { ResetPassword } from "features/Authentication/pages/ResetPassword";
import { Home } from "features/home/Home";
import { Jobs } from "features/jobs/pages/Jobs";
import { Talents } from "features/talents/pages/Talents";
import { JobDetails } from "features/jobs/pages/JobDetails";
import { Folders } from "features/folders/pages/Folders";
import { Pricing } from "features/pricing/pages/Pricing";
import { Settings } from "features/settings/Settings";
import { NotFoundPage } from "components/layout/NotFoundPage";
import { TalentDetails } from "features/talents/pages/TalentDetails";
import { SingleFolder } from "features/folders/pages/SingleFolder";
import { Invite } from "features/invites/pages/Invite";
import  Messages  from "features/message/pages/Messages";
import { RequireAuth } from "react-auth-kit";
import Team from "features/team/pages/Team";
import { MeetingSettings } from "features/settings/MeetingSettings";
import { GoogleOauthCallback } from "features/Authentication/pages/GoogleOauthCallback";
import { ZoomOauthCallback } from "features/Authentication/pages/ZoomOauthCallback";
import TermsAndConditions from "features/otherPages/TermsAndConditions";
import PrivacyPolicy from "features/otherPages/privacyPolicy";
import { VerifyPayment } from "features/pricing/pages/VerifyPayment";
import { TeamInvite } from "features/invites/components/TeamInvite";


import { AdminSignIn } from "admin/Authentication/pages/SignIn";
import { AdminForgotPassword } from "admin/Authentication/pages/ForgotPassword";
import { AdminResetPassword } from "admin/Authentication/pages/ResetPassword";
import { AdminHome } from "admin/home/Home";
import { AdminJobs } from "admin/jobs/pages/Jobs";
import { AdminTalents } from "admin/talents/pages/Talents";

import { AdminJobDetails } from "admin/jobs/pages/JobDetails";
import { AdminPricing } from "admin/finance/pages/Pricing";
import { AdminFinance } from "admin/finance/Finance";
import { AdminSettings } from "admin/settings/Settings";
import { AdminTalentDetails } from "admin/talents/pages/TalentDetails";
import { AdminSingleFolder } from "admin/folders/pages/SingleFolder";
import { AdminInvite } from "admin/invites/pages/Invite";
import  AdminMessages  from "admin/message/pages/Messages";
import AdminTeam from "admin/team/pages/Team";
import { AdminRecruiter } from "admin/recruiters/pages/Recruiter";
import { AdminUsers } from "admin/users/pages/Users";
import { AdminRoles } from "admin/users/pages/Roles";
import { AdminRecruiterDetails } from "admin/recruiters/pages/RecruiterDetails";


export const AllRoutes = () => {
  return (
    <Router>
      <GlobalContextProvider>
        <Routes>
          <Route path={routePath.sign_in} element={<SignIn />} />
          <Route path={routePath.sign_up} element={<SignUp />} />
          <Route path={routePath.google} element={<GoogleCallback />} />
          <Route path={routePath.linkedin} element={<LinkedinCallback />} />
          <Route path={routePath.googleOauth} element={<GoogleOauthCallback />} />
          <Route path={routePath.zoomOauth} element={<ZoomOauthCallback />} />
          <Route
            path={routePath.forgot_password}
            element={<ForgotPassword />}
          />
          <Route path={routePath.reset_password} element={<ResetPassword />} />
          <Route
            path={routePath.home}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.jobs}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Jobs />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.jobDetails().format}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <JobDetails />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.talents}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Talents />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.talentDetails().format}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <TalentDetails />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.folders}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Folders />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.folderDetails().format}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <SingleFolder />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.pricing}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Pricing />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.verifyPayment}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <VerifyPayment />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.teamInvite}
            element={
                <TeamInvite />
            }
          />

          <Route
            path={routePath.settings}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.meetingSettings}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <MeetingSettings />
              </RequireAuth>
            }
          />
          
          <Route
            path={routePath.invites}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Invite />
              </RequireAuth>
            }
          />

          <Route
            path={routePath.message}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Messages />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.messages}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Messages />
              </RequireAuth>
            }
          />

          <Route
            path={routePath.team}
            element={
              <RequireAuth loginPath={routePath.sign_in}>
                <Team />
              </RequireAuth>
            }
          />
          <Route
            path={routePath.terms}
            element={
              <TermsAndConditions />
            }
          />

          <Route
            path={routePath.privacy}
            element={
              <PrivacyPolicy />
            }
          />

          <Route path="*" element={<NotFoundPage />} />


          {/** Admin Routes */}

          <Route path={adminRoutePath.sign_in} element={<AdminSignIn />} />
          
          <Route
            path={adminRoutePath.forgot_password}
            element={<AdminForgotPassword />}
          />
          <Route path={adminRoutePath.reset_password} element={<AdminResetPassword />} />
          <Route
            path={adminRoutePath.users}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminUsers />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.roles}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminRoles />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.home}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminHome />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.jobs}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminJobs />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.jobDetails().format}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminJobDetails />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.talents}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminTalents />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.talentDetails().format}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminTalentDetails />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.recruiterDetails().format}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminRecruiterDetails />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.recruiters}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminRecruiter />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.folderDetails().format}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminSingleFolder />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.pricing}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminPricing />
              </RequireAuth>
            }
          />

          <Route
            path={adminRoutePath.settings}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminSettings />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.finance}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminFinance />
              </RequireAuth>
            }
          />
          
          
          <Route
            path={adminRoutePath.invites}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminInvite />
              </RequireAuth>
            }
          />

          <Route
            path={adminRoutePath.message}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminMessages />
              </RequireAuth>
            }
          />
          <Route
            path={adminRoutePath.messages}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminMessages />
              </RequireAuth>
            }
          />

          <Route
            path={adminRoutePath.team}
            element={
              <RequireAuth loginPath={adminRoutePath.sign_in}>
                <AdminTeam />
              </RequireAuth>
            }
          />
        </Routes>
      </GlobalContextProvider>
    </Router>
  );
};
