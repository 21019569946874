import { Tabs } from "antd";
import { DashboardLayout } from "components/layout/components/DashboardLayout";
import { Profile } from "./components/Profile";
import { Navbar } from "components/layout/components/Navbar";
import { Password } from "./components/Password";
import { Billings } from "./components/Billings";
import { TalentProfile } from "./components/talentProfile/TalentProfile";
import { PersonalityTest } from "./components/talentProfile/PersonalityTest";
import { Documents } from "./components/talentProfile/Documents";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { RecruiterDocuments } from "./components/RecruiterDocuments";

export const Settings = () => {
  const {token, userInfo } = useGetUserInfo();
  return (
    <DashboardLayout>
      <Navbar title="Settings" description="" />
      <Tabs defaultActiveKey="1" className="" size="large">
        <Tabs.TabPane tab="Profile" key="1">
          {userInfo.type === "talent" ? <TalentProfile /> : <Profile />}
        </Tabs.TabPane>
        {userInfo.type === "talent" && (
          <Tabs.TabPane tab="Documents" key="3">
            <Documents />
          </Tabs.TabPane>
        )}

        {/*userInfo.type !== "talent" && (
          <Tabs.TabPane tab="Documents" key="4">
            <RecruiterDocuments />
          </Tabs.TabPane>
        )*/}
        

        {userInfo.type === "talent" && (
          <Tabs.TabPane tab="Personality Test" key="5">
            <PersonalityTest />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="Password" key="6">
          <Password />
        </Tabs.TabPane>
        { userInfo?.recruiter?.parent_id != null || userInfo?.current_team !== undefined ? null :
          <Tabs.TabPane tab="Plans and Billings" key="7">
            <Billings />
          </Tabs.TabPane>
        }
      </Tabs>
    </DashboardLayout>
  );
};
