import React, { useEffect, useState } from "react";
import cloudIcon from "assets/icons/cloud.png";

interface DragNDropProps {
  onFileSelected: any;
  width?: string;
  height?: string;
}

const DragNDrop: React.FunctionComponent<DragNDropProps> = ({
  onFileSelected,
  width,
  height,
}) => {
  const [file, setFile] = useState<File>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files?.[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  useEffect(() => {
    onFileSelected(file);
  }, [file, onFileSelected]);

  return (
    <section className="flex flex-col items-center" style={{ width: width, height: height }}>
      
        <div
          className={`flex flex-col items-center w-full border border-gray-200 rounded-md px-4 ${
            file ? "upload-box active" : "upload-box"
          }`}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <>
            <input
              type="file"
              hidden={true}
              id="browse"
              onChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.webp,.pdf"
              style={{ width: width, height: height, }}
            />
            <label htmlFor="browse" className="browse-btn">
              <div className="flex flex-col justify-center items-center py-2 px-8">
                <img src={cloudIcon} alt="upload" className="w-20 h-20" />
                <p><span className="font-bold">Click to upload </span> <span> or drag and drop</span></p>
                <p className="text-sm text-gray-500 mt-2">
                    JPG, PNG, WEBP or PDF (max. 2MB)
                </p>
              </div>
            </label>
          </>
          {file && (
            <div className="text-green">
              {file.type.startsWith('image/') ? (
                <div className="flex items-center gap-x-2 pt-4">
                  <img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '60px' }} className="border border-primary " />
                  <p>{file.name}</p>
                </div>
              ) : (
                <div className="flex items-center gap-x-2 pt-4">
                  <i className="ri-file-pdf-line text-6xl text-primary"></i>
                  <p>{file.name}</p>
                </div>
              )}
            </div>
          )}
        </div>
      
    </section>
  );
};

export default DragNDrop;
