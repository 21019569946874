import { useEffect, useState } from "react";
import avatar from "assets/images/default.webp";
import { Drawer } from "antd";
import { SideBar } from "../sidebar/Sidebar";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { END_POINT } from "config/environment";
//import { messaging } from "../../../../firebase";
//import { openNotification } from "utils/notification";
//import { onMessage } from "firebase/messaging";

interface navProps {
  title: string;
  description: string;
}

export const Navbar = ({ description, title }: navProps) => {
  const [open, setOpen] = useState(false);
  const { userInfo, token } = useGetUserInfo();

  /*useEffect(()=>{
    onMessage(messaging, (payload:any) => {
      openNotification({
        title: payload.notification.title,
        state: "info",
        description: payload.notification.body,
        duration: 6.0,
      });
    });
  },[])
  */
  return (
    <>
      <div className="w-full py-3 mb-3 flex justify-between items-center">
        <i
          className="ri-menu-line lg:hidden text-xl cursor-pointer"
          onClick={() => setOpen(true)}
        ></i>
        <div className="lg:flex hidden">
          <div>
            <h1 className="font-bold text-xl">{title}</h1>
            <h3 className="text-sm">{description}</h3>
          </div>
        </div>

        <div className="flex items-center gap-x-4">
          <i className="ri-search-line text-xl cursor-pointer"></i>
          <i className="ri-notification-3-line text-xl cursor-pointer"></i>
          <img src={userInfo?.photo != null ? END_POINT.BASE+'/storage/'+userInfo?.photo : avatar} alt="avatar" className="h-12 w-12 rounded-full mb-2"/>
        </div>
      </div>

      {/* Drawer for mobile navbar */}
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        style={{ background: "var(--app-color-primary)" }}
      >
        <SideBar />
      </Drawer>
    </>
  );
};
